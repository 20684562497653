import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axiosInstance from '../../api/axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

function BillingTab() {
    const [creditFromDate, setCreditFromDate] = useState(dayjs().startOf('year'));
    const [creditToDate, setCreditToDate] = useState(dayjs());
    const [creditHistory, setCreditHistory] = useState([]);
    const [filteredCreditHistory, setFilteredCreditHistory] = useState([]);
    const [openTab, setOpenTab] = useState(1);
    const [transaction, setTransaction] = useState([]);
    const [fromDate, setFromDate] = useState(dayjs().startOf('year'));
    const [toDate, setToDate] = useState(dayjs());
    const [filteredTransactions, setFilteredTransactions] = useState([]);

    const PaymentapiURL = process.env.REACT_APP_PAYMENT_SERVICE_URL;

    // Convert UTC to local timezone
    const convertToLocalTime = (date) => dayjs.utc(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

    // Filter transactions based on date range
    const filterData = (data, from, to, dateField) => 
        data.filter((item) => {
            const date = dayjs(item[dateField]);
            return date.isBetween(from, to, null, '[]');
        });

    // Filter credit and transaction history whenever date range changes
    useEffect(() => {
        setFilteredTransactions(filterData(transaction, fromDate, toDate, 'payment_date_time'));
    }, [fromDate, toDate, transaction]);

    useEffect(() => {
        setFilteredCreditHistory(filterData(creditHistory, creditFromDate, creditToDate, 'created_date'));
    }, [creditFromDate, creditToDate, creditHistory]);

    // Fetch credit history
    useEffect(() => {
        axiosInstance.get(`${PaymentapiURL}/creditHistory/`)
            .then(response => {
                setCreditHistory(response.data.data || []);
            })
            .catch(error => console.error('Error fetching credit history:', error));
    }, [PaymentapiURL]);

    // Fetch transaction history
    useEffect(() => {
        axiosInstance.get(`${PaymentapiURL}/paymentHistory/`)
            .then(response => {
                setTransaction(response.data.data || []);
            })
            .catch(error => console.error('Error fetching transactions:', error));
    }, [PaymentapiURL]);

    return (
        <div className="flex flex-wrap">
            <div className="w-full">
                <ul className="flex list-none px-6 border-b space-x-8" role="tablist">
                    {['Payment', 'Usage'].map((tab, index) => (
                        <li key={tab} className={`${openTab === index + 1 ? "text-tab-active-color border-b-4 border-tab-active-color" : "text-gray-60"} text-base font-bold px-1.5 pb-3`}>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); setOpenTab(index + 1); }}
                                role="tablist"
                            >
                                {tab}
                            </a>
                        </li>
                    ))}
                </ul>

                <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                    <div className="px-4 py-5 flex-auto">
                        <div className="tab-content tab-space">
                            {/* Payment Tab */}
                            {openTab === 1 && (
                                <div>
                                    <div className="flex justify-end mb-6">
                                        <span className="mr-2 mt-6 font-normal text-sm">Filter By:</span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker label="From" value={fromDate} onChange={setFromDate} />
                                                <DatePicker label="To" value={toDate} onChange={setToDate} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="container mx-auto h-56 overflow-y-auto">
                                        <table className="min-w-full">
                                            <thead className="bg-tab-not-active-color">
                                                <tr>
                                                    <th className="py-2 px-4 text-left">Date</th>
                                                    <th className="py-2 px-4 text-left">Amount</th>
                                                    <th className="py-2 px-4 text-left">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredTransactions.length ? filteredTransactions.map((ele, i) => (
                                                    <tr key={i}>
                                                        <td className="py-2 px-4">{convertToLocalTime(ele.payment_date_time)}</td>
                                                        <td className="py-2 px-4">${ele.amount}</td>
                                                        <td className="py-2 px-4">{ele.payment_status}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td className="text-center py-2" colSpan="3">No Transactions</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            {/* Usage Tab */}
                            {openTab === 2 && (
                                <div>
                                    <div className="flex justify-end mb-6">
                                        <span className="mr-2 mt-6 font-normal text-sm">Filter By:</span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker label="From" value={creditFromDate} onChange={setCreditFromDate} />
                                                <DatePicker label="To" value={creditToDate} onChange={setCreditToDate} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="container mx-auto h-56 overflow-y-auto">
                                        <table className="min-w-full">
                                            <thead className="bg-tab-not-active-color">
                                                <tr>
                                                    <th className="py-2 px-4 text-left">Date</th>
                                                    <th className="py-2 px-4 text-left">Purpose</th>
                                                    <th className="py-2 px-4 text-left">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredCreditHistory.length ? filteredCreditHistory.map((ele, i) => (
                                                    <tr key={i}>
                                                        <td className="py-2 px-4">{convertToLocalTime(ele.created_date)}</td>
                                                        <td className="py-2 px-4">{ele.purpose}</td>
                                                        <td className="py-2 px-4">${ele.amount}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td className="text-center py-2" colSpan="3">No Credit History</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingTab;
