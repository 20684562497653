import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import axiosInstance from "../../api/axios";

const ImportFileModal = ({ isOpen, setIsOpen }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    handleFiles(files);
    setSelectedFile(event.target.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
    setSelectedFile(files[0]);
  };

  const handleFiles = (files) => {
    if (uploadedFiles.length !== 0) {
      toast("Cannot Upload Multiple Files");
      return;
    }
    const newFiles = Array.from(files);
    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const uploadBtn = async () => {
    const formData = new FormData();
    if (!selectedFile) {
      toast("Upload a CSV File");
      return;
    }
    formData.append("df", selectedFile);

    try {
      const response = await axiosInstance.post(`/manage/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentage);
        },
      });

      if (response?.data) {
        toast(response?.data?.message);
        setUploadProgress(0);
        setSelectedFile(null);
      }
    } catch (error) {
      toast(error.response.data.error);
      setUploadProgress(0);
    } finally {
      setIsOpen(false)
    }
  };

  return (
    <>
      {isOpen ? (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-10">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          {/* Main Component */}
          <div className="relative mx-auto my-auto max-w-4xl w-[800px] h-[300px] z-50">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex justify-between items-center p-5 border-b border-solid border-slate-200 rounded-t">
                <span className="font-semibold text-[18px]">Import File</span>
                <button
                  className="px-4"
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  <MdClose className="w-5 h-5 text-2xl" />
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div
                  className="border-2 border-dashed rounded py-10 my-2 text-center"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div className="text-secondry-clr font-medium text-base">
                    <label
                      htmlFor="file-upload"
                      className="cursor-pointer rounded-md py-2 px-4 inline-block underline text-purple-700"
                    >
                      Click to upload
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                    />
                    <span className="text-purple-500 -ml-3">
                      or drag & drop
                    </span>
                  </div>
                </div>

                {/* Show upload progress and file name if available */}
                {selectedFile && (
                  <div className="mt-3 text-center">
                    <span className="text-lg font-medium text-gray-800">
                      {selectedFile.name}
                    </span>
                    <div className="mt-2 bg-white rounded-lg shadow-lg w-full max-w-[90%] mx-auto p-2">
                      <div
                        className="bg-purple-500 h-2 rounded transition-all duration-300"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <span className="text-purple-700 font-medium text-sm mt-1">
                      {uploadProgress}%
                    </span>
                  </div>
                )}

                {/* Modal Buttons */}
                <div className="mt-5 flex justify-end gap-5">
                  <button
                    type="button"
                    className="hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-8 py-3"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-10 py-3"
                    onClick={uploadBtn}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ImportFileModal;
