import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import GetApiKeyModal from "../UserProfileActions/GetApiKeyModal";
import Slider from "../Slider";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ImSpinner3 } from "react-icons/im";
import Markdown from "react-markdown";
import { BsDot } from "react-icons/bs";
import { IoCodeSharp } from "react-icons/io5";
import axiosInstance from "../../api/axios";
import { AiOutlineSend } from "react-icons/ai";

const GetUi = ({ modelData, category, modelStatusLight }) => {
  const location = useLocation();
  const { model, modelIp } = location.state || {};

  const [isGetAPIModalOpen, setIsGetAPIModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [maxTokens, setMaxTokens] = useState(150);
  const [temperature, setTemperature] = useState(0.7);
  const [topP, setTopP] = useState(0.9);
  const [repetitionPenalty, setRepetitionPenalty] = useState(1.2);
  const [chatHistory, setChatHistory] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);

  const chatHistoryRef = useRef(null);

  // useEffect(() => {
  //   const storedApiKey = sessionStorage.getItem("apiKey");
  //   if (storedApiKey) {
  //     setApiKey(storedApiKey);
  //   }
  // }, []);
  useEffect(() => {
    const fetchApiKey = async () => {
      const storedApiKey = sessionStorage.getItem("apiKey");
      if (storedApiKey) {
        setApiKey(storedApiKey);
      } else {
        try {
          const response = await axiosInstance.post("/nocode/generate_api_key");
          const apiKey = response.data.data.api_key;
          sessionStorage.setItem("apiKey", apiKey);
          setApiKey(apiKey);
        } catch (error) {
          console.error("Error generating API key:", error);
        }
      }
    };

    fetchApiKey();
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleGetAPIToken = () => {
    setIsGetAPIModalOpen(true);
  };

  const saveApiKey = (key) => {
    setApiKey(key);
    sessionStorage.setItem("apiKey", key);
  };

  const handleFormSubmit = async () => {
    if (!query) return;

    // Add the user's new message to the chat history temporarily
    const newUserMessage = { content: query, role: "user" };
    // setChatHistory((prev) => [...prev, newUserMessage]);
    setChatHistory([newUserMessage]);

    setLoading(true);
    try {
      const headers = {
        Authorization: apiKey,
        "Content-Type": "application/json",
      };

      const data = {
        prompt: query,
        max_new_tokens: Number(maxTokens),
        temperature: Number(temperature),
        top_p: Number(topP),
        repetition_penalty: Number(repetitionPenalty),
      };

      const response = await axiosInstance.post(modelIp, data, { headers });

      const assistantMessages = response.data.data.map((msg) => ({
        content: msg.content,
        role: msg.role,
      }));

      // Replace the last message (user's message) with the full response from backend
      setChatHistory((prev) => [
        ...prev.slice(0, -1), // Remove the last user message
        ...assistantMessages, // Add full response from backend
      ]);
    } catch (error) {
      console.error("API call failed:", error);
      setChatHistory((prev) => [
        ...prev,
        {
          content: "Failed to fetch response. Please try again.",
          role: "error",
        },
      ]);
    } finally {
      setQuery(""); // Clear the input field after submission
      setLoading(false);
    }
  };

  // const handleFormSubmit = async () => {
  //   if (!query) return;

  //   const newUserMessage = { content: query, role: "user" };
  //   setChatHistory([newUserMessage]); // Start with the user's message only

  //   setLoading(true);
  //   try {
  //     const headers = {
  //       Authorization: apiKey,
  //       "Content-Type": "application/json",
  //     };

  //     const data = {
  //       prompt: query,
  //       max_new_tokens: Number(maxTokens),
  //       temperature: Number(temperature),
  //       top_p: Number(topP),
  //       repetition_penalty: Number(repetitionPenalty),
  //     };

  //     const response = await axios.post(modelIp, data, { headers });

  //     const assistantMessages = response.data.data.map((msg) => ({
  //       content: msg.content,
  //       role: msg.role,
  //     }));

  //     setChatHistory(assistantMessages); // Replace the entire chat history with the new response

  //   } catch (error) {
  //     console.error("API call failed:", error);
  //     setChatHistory([
  //       { content: "Failed to fetch response. Please try again.", role: "error" },
  //     ]);
  //   } finally {
  //     setQuery(""); // Clear the input field after submission
  //     setLoading(false);
  //   }
  // };

  // const handleFormSubmit = async () => {
  //   if (!query) return;

  //   const newUserMessage = { content: query, role: "user" };
  //   setChatHistory((prev) => [...prev, newUserMessage]);

  //   setLoading(true);
  //   try {
  //     const headers = {
  //       Authorization: apiKey,
  //       "Content-Type": "application/json",
  //     };

  //     const data = {
  //       prompt: query,
  //       max_new_tokens: Number(maxTokens),
  //       temperature: Number(temperature),
  //       top_p: Number(topP),
  //       repetition_penalty: Number(repetitionPenalty),
  //     };

  //     const response = await axios.post(modelIp, data, { headers });

  //     const assistantMessages = response.data.data.map((msg) => ({
  //       content: msg.content,
  //       role: msg.role,
  //     }));

  //     setChatHistory((prev) => [...prev, ...assistantMessages]);
  //   } catch (error) {
  //     console.error("API call failed:", error);
  //     setChatHistory((prev) => [
  //       ...prev,
  //       { content: "Failed to fetch response. Please try again.", role: "error" },
  //     ]);
  //   } finally {
  //     setQuery(""); // Clear the input field after submission
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        {/* Navbar Section */}
        <div className="ml-[90px]">
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>
        {/* Page content */}
        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            <div className="flex items-center py-4">
              {/* <Link 
              to={{
                pathname: "/modelStudio",
                search: "?tab=huggingFaceModels"
              }}
              state={{ openTab: 2 }}
            >
              <Typography variant="h6" component="h2" className="text-xl font-bold text-heading-black">
                Model Studio
              </Typography>
            </Link> */}
              <Link to="/modelStudio" state={{ openTab: 2 }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className="text-xl font-bold text-heading-black"
                >
                  Model Studio
                </Typography>
              </Link>

              <MdKeyboardArrowRight className="text-2xl" />
              <Typography
                variant="h6"
                component="h2"
                className="text-xl font-bold text-heading-black"
              >
                Interactive UI
              </Typography>
            </div>

            {/* Model Info Section */}
            <div className="min-h-[132px] w-full bg-white pl-4 pr-8 mb-4 shadow-md flex flex-wrap justify-between items-center">
              <div className="flex flex-wrap gap-x-12">
                <img
                  src="https://ai-platform-images.s3.amazonaws.com/AI-Image/Subcategories/Subcategories/Gemma.png"
                  className="h-[100px] w-[140px] border rounded-md"
                  alt="Model Image"
                />
                <div>
                  <span className="text-[21px] font-[600]">
                    {model.model_name}
                  </span>
                  <div className="flex flex-wrap gap-x-12 mt-4">
                    <div>
                      <p className="text-[14px] font-[400]">Category</p>
                      <p className="text-[14px] font-[500]">Text-Generation</p>
                    </div>
                    <div>
                      <p className="text-[14px] font-[400]">Visibility</p>
                      <p className="text-[14px] font-[500]">Private</p>
                    </div>
                    <div>
                      <p className="text-[14px] font-[400]">Status</p>

                      <p className="text-[14px] font-[500] flex items-center -mt-1 text-[#008b16]">
                        <BsDot className="text-3xl -mx-2" /> On
                      </p>
                    </div>
                    {/* <div>
                      <p className="text-[14px] font-[400]">Links</p>
                      
                        <a href="https://ai.google.dev/gemma/terms" target="blank">
                          <span className="text-[14px] font-[500] hover:text-blue-500">
                            <u>Paper</u>
                          </span>
                        </a>
                      
                      <span className="text-[14px] font-[500]"> and </span>
                      
                        <a href="https://ai.google.dev/gemma/terms" target="blank">
                          <span className="text-[14px] font-[500] hover:text-blue-500">
                            <u>License</u>
                          </span>
                        </a>
                      
                    </div> */}
                  </div>
                </div>
              </div>
              {/* Get API Token Button */}
              <button
                className="classic-button flex items-center justify-center h-[40px] min-w-[150px] text-[14px] font-semibold mt-2"
                onClick={handleGetAPIToken}
              >
                <IoCodeSharp className="mr-1" />
                <span>Get API Token</span>
              </button>
            </div>

            <Paper elevation={3} sx={{ p: 2, mb: 4, bgcolor: "#fff" }}>
              <Box className="flex w-full h-full">
                <div className="w-1/2 h-full p-8">
                  <p className="text-[16px] font-[600] mb-8">
                    Generate Response
                  </p>
                  {/* <TextField
                    label="API Key"
                    placeholder="API Key"
                    fullWidth
                    value={apiKey}
                    onChange={(e) => saveApiKey(e.target.value)}
                    sx={{ marginBottom: 2 }}
                  /> */}

                  <div className="mb-10 mt-6">
                    <Slider
                      label="Max New Tokens"
                      value={maxTokens}
                      // setValue={setMaxTokens}
                      setValue={(newValue) => setMaxTokens(Number(newValue))}
                      min={100}
                      max={2000}
                      step={50}
                      text={
                        "The maximum numbers of tokens to generate, ignoring the number of tokens in the prompt.(One token is roughly 4 characters for standard English text)"
                      }
                    />

                    <Slider
                      label="Temperature"
                      value={temperature}
                      // setValue={setTemperature}
                      setValue={(newValue) => setTemperature(Number(newValue))}
                      min={0.0}
                      max={1.0}
                      step={0.01}
                      text={
                        "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive."
                      }
                    />
                    <Slider
                      label="Top P"
                      value={topP}
                      // setValue={setTopP}
                      setValue={(newValue) => setTopP(Number(newValue))}
                      min={0.0}
                      max={1.0}
                      step={0.01}
                      text={
                        "Only tokens within the top top_p% probability are considered."
                      }
                    />
                    <Slider
                      label="Repetition Penalty"
                      value={repetitionPenalty}
                      setValue={(newValue) =>
                        setRepetitionPenalty(Number(newValue))
                      }
                      min={1.0}
                      max={2.0}
                      step={0.01}
                      text={
                        "The parameter for repetition penalty. 1.0 means no penalty."
                      }
                    />
                  </div>

                  {/* {loading ? (
                    <button className="loading-button w-[136px] h-[52px] mx-auto">
                      <ImSpinner3 className="text-xl mr-1 animate-spin" />
                      Running
                    </button>
                  ) : (
                    <button
                      className="classic-button w-[136px] h-[52px] mx-auto"
                      onClick={handleFormSubmit}
                    >
                      Run
                    </button>
                  )} */}
                </div>

                <div className="w-1/2 min-h-[538px] p-8 border-l-2">
                  <p className="text-[16px] font-[600] mb-8">Chat History</p>
                  <div
                    ref={chatHistoryRef}
                    className="chat-history overflow-y-auto max-h-[500px] p-4 border rounded-lg"
                  >
                    {chatHistory.map((message, index) => (
                      <div
                        key={index}
                        className={`p-3 mb-2 rounded-lg ${
                          message.role === "user"
                            ? "bg-gray-300 text-right rounded-l-lg" // User messages: grey, right-aligned
                            : message.role === "assistant"
                            ? "bg-purple-300 text-left rounded-r-lg" // Assistant messages: purple, left-aligned
                            : "bg-red-100"
                        }`}
                      >
                        <Markdown className="text-base overflow-auto">
                          {message.content}
                        </Markdown>
                      </div>
                    ))}
                  </div>
                  {/* <TextField
                    className="w-full mt-4"
                    label="User Query"
                    multiline
                    rows={4}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey) {
                        e.preventDefault();
                        setQuery((prev) => prev + "\n");
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        handleFormSubmit();
                      }
                    }}
                  /> */}
                  <div className="flex items-center mt-4">
                    <TextField
                      label="Your Query"
                      placeholder="Type your query here..."
                      fullWidth
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && e.shiftKey) {
                          e.preventDefault();
                          setQuery((prev) => prev + "\n");
                        } else if (e.key === "Enter") {
                          e.preventDefault();
                          handleFormSubmit();
                        }
                      }}
                      sx={{ flexGrow: 1, mr: 1 }}
                      multiline
                      rows={2}
                    />
                    <IconButton
                      onClick={handleFormSubmit}
                      disabled={loading}
                      color="primary"
                    >
                      {loading ? (
                        <ImSpinner3 className="animate-spin" />
                      ) : (
                        <AiOutlineSend />
                      )}
                    </IconButton>
                  </div>
                </div>
              </Box>
            </Paper>
          </Container>
        </div>
        {/* Footer Section */}
        <div className="ml-[90px]">
          <Footer />
        </div>
      </div>

      <GetApiKeyModal
        isGetAPIModalOpen={isGetAPIModalOpen}
        setIsGetAPIModalOpen={setIsGetAPIModalOpen}
      />
    </div>
  );
};

export default GetUi;
