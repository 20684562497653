import React, { useState, useRef, useEffect } from "react"; // Third-party Library Imports:
import { FaInfoCircle } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5"; // MUI Component Imports:
import nvidiaLogo from "../../../assets/nvdia logo.png";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  FormControl,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material"; // Custom/Project Imports:
// import { Opacity } from "@mui/icons-material";
import axiosInstance from "../../../api/axios";
import AddCreditModal from "../../AddCreditModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { steps } from "./StubsData/modelsStubs";
// import { addHuggingModel } from "../../services/models";

const AddHuggingModal = ({ isOpen, setIsOpen, isModalscreen2FormValid }) => {
  const [loading, setLoading] = useState(false);
  const [showModalscreen1, setShowModalScreen1] = useState(true);
  const [showModalscreen2, setShowModalScreen2] = useState(false);
  const [showModalscreen3, setShowModalScreen3] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isCreditOpen, setIsCreditOpen] = useState(false);
  const navigate = useNavigate();

  const [disable, setDisable] = useState(false);
  const autoCloseTimeOutRef = useRef(null);
  const [modelSelection, setModelSelection] = useState({
    huggingfacemodel: "google/gemma-2-2b-it",
    model_name: "meta-lamma",
    huggingFaceToken: "xxxxxxxxxxxxxxxxxxxxxxxx",
    model_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.",
  });
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      label: "Step 01",
      description: `Basic Info`,
    },
    {
      label: "Step 02",
      description: `Model Selection`,
    },
    {
      label: "Step 03",
      description: `GPU Selection`,
    },
  ];

  const [selectedGPU, setSelectedGPU] = useState("");
  const [gpuOptions, setGpuOptions] = useState([]);
  const [gpuCount, setGpuCount] = useState(1);
  const [selectedGPUPrice, setSelectedGPUPrice] = useState(0);

  // const gpuOptions = [
  //   { name: "NVIDIA A100 80 GB", price: 12 },
  //   { name: "NVIDIA L4 24 GB", price: 7 },
  //   { name: "NVIDIA T4 16 GB", price: 5 },
  // ];

  const handleSelectGPU = (gpuname, price) => {
    setSelectedGPU(gpuname);
    setSelectedGPUPrice(price);
  };

  const handleSliderChange = (event) => {
    setGpuCount(event.target.value);
  };

  const getTotalPrice = () => {
    const selectedOption = gpuOptions.find(
      (option) => option.name === selectedGPU
    );
    // setTotalCost(selectedOption?.price || 0 );
    return gpuCount * (selectedOption?.price || 0).toFixed(2);
  };

  //   const handleModelSelection = (event) => {
  //     const { name, value } = event.target;
  //     setModelSelection((prevState) => ({
  //       ...prevState,
  //       [name]: value.replace(defaultText, ""),
  //     }));
  //   };

  const handleTune = async () => {
    setLoading(true);
    const payload = {
      model_id: modelSelection.huggingfacemodel,
      model_name: modelSelection.model_name,
      gpu_name: selectedGPU,
      number_of_gpu: gpuCount,
      price: selectedGPUPrice,
      description: modelSelection.model_description,
      hf_token: modelSelection.huggingFaceToken,
    };
    const modalTimeout = setTimeout(() => {
      setIsOpen(false);
    }, 5000);

    try {
      const response = await axiosInstance.post(
        `/tune/deploy_huggingmodel`,
        payload
      );
      toast(response.data.message);

      if (response.data.message === "Insufficient credits.") {
        setIsCreditOpen(true);
      }

      console.log("Model tuning initiated:", response.data);

      if (response.data.success) {
      }
    } catch (error) {
      toast(error.response?.data?.message);
      console.error("Error tuning model:", error);
    } finally {
      setLoading(false);

      clearTimeout(modalTimeout);
      setIsOpen(false);
    }
  };

  const stepperStyle = {
    "& .MuiStepIcon-root": {
      color: "#d1d5db", // Default color for inactive step (gray)
      fontSize: "2rem", // Adjust size if needed
    },
    "& .Mui-active": {
      "& .MuiStepIcon-root": {
        color: "#8F27C1", // Active step color (purple)
      },
      "& .MuiStepConnector-line": {
        borderColor: "#8F27C1", // Connector color for active step
      },
    },
    "& .Mui-completed": {
      "& .MuiStepIcon-root": {
        color: "#8F27C1", // Completed step color (purple)
      },
      "& .MuiStepConnector-line": {
        borderColor: "#8F27C1", // Connector color for completed step
      },
    },
    "& .MuiStepLabel-root .MuiStepLabel-label": {
      color: "#6b7280", // Default text color (gray)
    },
    "& .Mui-active .MuiStepLabel-label": {
      color: "#fff", // Text color for active step (purple)
    },
    "& .Mui-completed .MuiStepLabel-label": {
      color: "#8F27C1", // Text color for completed step (purple)
    },
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Axios API call to validate model and token
  const validateModelAndToken = async () => {
    setErrorMessage();
    setLoading(true);
    try {
      const response = await axiosInstance.post("/nocode/huggingface_check", {
        model_id: modelSelection.huggingfacemodel,
        huggingface_token: modelSelection.huggingFaceToken,
      });

      if (response.data.success) {
        const gpuPlansResponse = await axiosInstance.get("/nocode/gpu_plans");

        // Log the entire response to inspect it
        console.log("GPU Plans API Response:", gpuPlansResponse);

        if (gpuPlansResponse.data.success) {
          const gpuData = gpuPlansResponse.data.data;

          // Log gpuData before setting it in state
          console.log("GPU Data:", gpuData);

          // Extract keys of the GPU data (e.g., ["NVIDIA A10G"])
          const gpuNames = Object.keys(gpuData);

          // Create an array of GPU options from the response
          const gpuOptionsArray = gpuNames.map((gpuName) => ({
            name: gpuName,
            price: gpuData[gpuName][0]?.price || 0, // Assuming only one option for each GPU
          }));

          console.log("Formatted GPU Options:", gpuOptionsArray);

          // Set the GPU options state with the formatted data
          setGpuOptions(gpuOptionsArray);
          setShowModalScreen2(false);
          setShowModalScreen3(true);
          handleNext();
        } else {
          // setErrorMessage("Failed to fetch GPU plans.");
          // console.log("Error fetching GPU plans:", gpuPlansResponse.data);
          showErrorToast("Failed to fetch GPU plans.");
        }
      } else {
        // setLoading(false);
        // toast("Invalid Hugging Face model or token..");
        // setErrorMessage("Invalid Hugging Face model or token.");
        showErrorToast("Invalid Hugging Face model or token");
        console.log("Invalid Hugging Face model or token");
      }
    } catch (error) {
      showErrorToast("Invalid Hugging Face model or token");
      // setLoading(false);
      // setErrorMessage("Invalid Hugging Face model or token.");
      console.log("Error occurred:", error);
    } finally {
      // setLoading(false);
      // setIsOpen(false)
      setLoading(false);
    }
  };

  // Utility function to show error toast once
  const showErrorToast = (message) => {
    setErrorMessage(message);
    toast.dismiss();
    toast(message);
  };

  //   const defaultText = "https://huggingface.co/";
  const isNextButtonEnabled =
    modelSelection.huggingfacemodel !== "" &&
    modelSelection.huggingFaceToken !== "" &&
    modelSelection.model_name !== "";

  return (
    <>
      {isOpen && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mb-10">
          {/* Dark Overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          {/* Main Compoent */}
          <div className="relative mx-auto my-auto max-w-3xl w-full z-50">
            <div className="m-auto bg-white rounded-xl pt-5 shadow-xl transform transition-all modal-main-component">
              <div>
                {/* Title & Close */}
                <div className="w-full px-5 pb-5 flex justify-between items-center">
                  <span className="font-semibold text-[18px]">
                    Add Hugging Face Models
                  </span>
                  <IoCloseSharp
                    className="text-2xl cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
                <hr className="w-full h-full" />
                {/* Content */}
                <div className="flex max-w-[935px] max-h-[600px] overflow-scroll modal-main-content">
                  {/* Left Section */}
                  <div className="w-3/12 h-inherit min-h-[360px] border-r flex flex-col overflow-auto">
                    <div className="flex items-center pt-4 ml-10">
                      <Box
                        sx={{
                          maxWidth: 400,
                        }}
                      >
                        <Stepper
                          activeStep={activeStep}
                          orientation="vertical"
                          sx={stepperStyle}
                        >
                          {steps.map((step, index) => (
                            <Step key={step.label}>
                              <StepLabel>{step.label}</StepLabel>
                              <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box
                                  sx={{
                                    mb: 2,
                                  }}
                                ></Box>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </div>
                  </div>

                  {/* Right Section */}
                  {showModalscreen1 && (
                    <div className="w-9/12 h-full flex flex-col p-6">
                      <p className="text-justify text-[18px] font-[600]">
                        Basic Info
                      </p>
                      <div className="my-10 text-left sm:mt-5 space-y-6">
                        <TextField
                          //   onChange={handleModelSelection}
                          name="model_name"
                          value={modelSelection.model_name}
                          onChange={(e) =>
                            setModelSelection({
                              ...modelSelection,
                              model_name: e.target.value,
                            })
                          }
                          defaultValue="meta-llama/Llama-3.1-8B-Instruct"
                          id="outlined-basic"
                          label={
                            <span>
                              Name Your Model
                              <span className="text-red-500">*</span>
                            </span>
                          }
                          variant="outlined"
                          className="w-10/12"
                        />
                        {/* Description Input */}
                        <TextField
                          id="model-description"
                          label={<span>Description</span>}
                          // InputLabelProps={{
                          //   sx: { fontSize: "1.2rem" },
                          //   shrink: true,
                          // }}
                          value={modelSelection.model_description}
                          onChange={(e) =>
                            setModelSelection({
                              ...modelSelection,
                              model_description: e.target.value,
                            })
                          }
                          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore and  a dolore magna aliqua."
                          multiline
                          rows={4}
                          className="w-full"
                          // onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      {/* bottom buttons */}
                      <div className="mt-5 mb-3 sm:mt-6 flex justify-end">
                        <button
                          type="button"
                          className="border border-black rounded-lg flex h-[48px] w-[115px] hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none text-[16px] font-[600] justify-center items-center mr-4"
                          onClick={() => {
                            setIsOpen(false);
                            setShowModalScreen1(true);
                          }}
                        >
                          Close
                        </button>

                        <button
                          type="button"
                          className="classic-button h-[48px] w-[110px] text-[18px] font-[600]"
                          //   className={`gen-button-2 flex h-[52px] w-[134px] text-[18px] justify-center items-center ml-5 ${
                          //     !isNextButtonEnabled
                          //       ? "opacity-50 cursor-not-allowed"
                          //       : ""
                          //   }`}
                          //   disabled={!isNextButtonEnabled}
                          onClick={() => {
                            setShowModalScreen1(false);
                            setShowModalScreen2(true); // handleAddNode();

                            handleNext();
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {/* model2 */}
                  {showModalscreen2 && (
                    <div className="w-9/12 h-full flex flex-col p-6">
                      <p className="text-justify text-[18px] font-[600]">
                        Model Selection
                      </p>
                      <div className="my-10 text-left sm:mt-5 space-y-6">
                        <TextField
                          label={
                            <span>
                              Hugging Face Model
                              <span className="text-red-500">*</span>
                            </span>
                          }
                          name="huggingfacemodel"
                          value={modelSelection.huggingfacemodel}
                          onChange={(e) =>
                            setModelSelection({
                              ...modelSelection,
                              huggingfacemodel: e.target.value,
                            })
                          }
                          className="w-10/12"
                          sx={{ height: 44 }}
                          variant="outlined"
                          // InputProps={{ readOnly: true }} // Makes the field read-only if you want to prevent edits
                        />
                        <p className="ml-4 text-[14px] font-[500] opacity-80">
                          Hugging Face model repository.{" "}
                          <a
                            href="https://huggingface.co/models?pipeline_tag=text-generation&sort=trending"
                            target="_blank"
                            rel="noopener noreferrer" //this impoves security
                            className=" ml-2 text-[#1171EA] underline-offset-0 font-bold hover:text-gen-color cursor-pointer"
                          >
                            View Models
                          </a>
                        </p>

                        <TextField
                          //   onChange={handleModelSelection}
                          name="huggingFaceToken"
                          value={modelSelection.huggingFaceToken}
                          onChange={(e) =>
                            setModelSelection({
                              ...modelSelection,
                              huggingFaceToken: e.target.value,
                            })
                          }
                          // defaultValue="Jennywilson_02@gmail.com"
                          id="outlined-basic"
                          label={
                            <span>
                              Hugging Face Token
                              <span className="text-red-500">*</span>
                            </span>
                          }
                          variant="outlined"
                          className="w-10/12"
                        />
                        {/* Login section */}
                        <p className="ml-4 text-[14px] font-[500] opacity-80">
                          Login to Hugging Face.
                          <a
                            href="https://huggingface.co/login"
                            target="_blank"
                            rel="noopener noreferrer" //this impoves security
                            className=" ml-2 text-[#1171EA] underline-offset-0 font-bold hover:text-gen-color cursor-pointer"
                          >
                            Login
                          </a>
                        </p>
                      </div>

                      {/* bottom buttons */}
                      <div className="mt-5 mb-3 sm:mt-6 flex justify-end">
                        <button
                          type="button"
                          className={`border border-black rounded-lg flex h-[48px] w-[115px] hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none text-[16px] font-[600] justify-center items-center mr-4 ${
                            disable ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          disabled={disable}
                          onClick={() => {
                            setShowModalScreen2(false);
                            setShowModalScreen1(true);
                            handleBack();
                          }}
                        >
                          Previous
                        </button>

                        <button
                          type="button"
                          className="classic-button h-[48px] w-[110px] text-[18px] font-[600]"
                          onClick={validateModelAndToken}
                          disabled={loading}
                        >
                          {loading ? "Validating..." : "Next"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* model3 */}
                  {showModalscreen3 && (
                    <div className="w-10/12 h-full flex flex-col px-8 py-6">
                      {/* Header */}
                      <p className="text-justify text-[18px] font-semibold mb-6">
                        GPU Selection
                      </p>
                      <div className="flex flex-col space-y-6">
                        {/* GPU Options */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                          {gpuOptions.map((gpu, index) => (
                            <div
                              key={index}
                              className={`px-2 border rounded-lg cursor-pointer shadow-sm min-w-fit ${
                                selectedGPU === gpu.name
                                  ? "border-purple-500 bg-purple-50"
                                  : "border-gray-200"
                              } hover:border-purple-400`}
                              onClick={() =>
                                handleSelectGPU(gpu.name, gpu.price)
                              }
                            >
                              <div className="flex items-center space-x-2">
                                <input
                                  type="radio"
                                  checked={selectedGPU === gpu.name}
                                  onChange={() => {
                                    handleSelectGPU(gpu.name, gpu.price);
                                    // handlePriceGPU(gpu.price);
                                  }}
                                  className="form-radio text-purple-500 focus:ring-purple-500"
                                />
                                <img
                                  src={nvidiaLogo}
                                  alt="Nvidia Logo"
                                  className=""
                                />
                                <p className="mt-2 pt-2 font-semibold text-sm">
                                  {gpu.name}
                                </p>
                              </div>
                              <p className="text-sm text-gray-500 my-2 ml-6">
                                from ${gpu.price} / hr
                              </p>
                            </div>
                          ))}
                        </div>

                        {/* GPU Count */}
                        <div className="flex items-center mb-6">
                          <label className="flex items-center text-sm mr-3 font-semibold">
                            GPU Count
                            <FaInfoCircle className="ml-1 text-gray-400" />
                          </label>
                          <input
                            type="range"
                            min="1"
                            max={gpuCount}
                            value={gpuCount}
                            onChange={handleSliderChange}
                            className="w-full mx-3"
                            style={{ accentColor: "#8F27C1" }}
                          />
                          <span className="font-semibold">{gpuCount}</span>
                          <p className="bg-gray-100 text-right font-semibold text-green-600 ml-6 border rounded-md p-2">
                            ${getTotalPrice()} / hr
                          </p>
                        </div>
                      </div>

                      {/* Bottom Buttons */}
                      <div className="mt-8 flex justify-end">
                        <button
                          type="button"
                          className={`border border-black rounded-lg h-[48px] w-[115px] hover:bg-gray-200 hover:text-gray-600 hover:border-gray-300 hover:shadow-md transition duration-200 ease-in-out focus:outline-none text-[16px] font-semibold mr-4 ${
                            disable ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          disabled={disable}
                          onClick={() => {
                            setShowModalScreen3(false);
                            setShowModalScreen2(true);
                            handleBack();
                          }}
                        >
                          Previous
                        </button>

                        <button
                          type="button"
                          className="classic-button h-[48px] w-[110px] text-[18px] font-semibold"
                          // onClick={() => setIsOpen(false)}
                          onClick={handleTune}
                          disabled={loading}
                        >
                          {loading ? "Deploying..." : "Deploy"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isCreditOpen} setIsOpen={setIsCreditOpen} />
    </>
  );
};

export default AddHuggingModal;
