import React, { useEffect, useRef, useState } from "react";
import loader from "../../assets/ui_img/loader.svg";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";
import { TbExternalLinkOff } from "react-icons/tb";
import { FiEye } from "react-icons/fi";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FaRegTrashAlt } from "react-icons/fa";
import DeployModal from "../DashboardActions/DeployModal";
const TunedModelTab = ({
  openTab,
  onTabChange,
  convertToLocalTime,
  handleRefreshToken,
}) => {
  const [loading, setLoading] = useState(false);

  const [models, setModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelsError, setModelsError] = useState(null);
  const [showActionsForModel, setShowActionsForModel] = useState(null);
  const [selectedModel, setSelectedModelName] = useState(null);
  const [selectedmodelId, setSelectedModelId] = useState(null);
  const [isDeployModalOpen, setIsDeployModalOpen] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const actionRef = useRef(null);
  const navigate = useNavigate();
  const [deleteTunedModel, setDeleteTunedModel] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [isBottomOverflowing, setIsBottomOverflowing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const LoaderComponent = () => (
    <ClipLoader color={"#000"} loading={true} size={24} />
  );

  // Function to load models
  const loadModels = async () => {
    // setModelsLoading(true);
    try {
      const response = await axiosInstance.get("/nocode/get-models");
      const modelsData = response?.data?.data;
      if (response.data.success && modelsData && modelsData.length > 0) {
        setModels(modelsData);
        console.log("Model details: " + models);
      } else {
        // Show nothing when array is empty
        setModels([]); // Clear models when there's no data
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadModels();
        }
      } else {
        setModelsError(error.message || "Failed to fetch models");
      }
    } finally {
      setModelsLoading(false);
    }
  };
  const viewDetails = (model) => {
    // Logic to view model details
    navigate("/view-detail", { state: { model } });
  };

  const deployModel = (modelId, modelName) => {
    // Logic to deploy the model
    // navigate("/deploy", { state: { modelId } });
    setSelectedModelName(modelName);
    // setSelectedPrice(price);
    setSelectedModelId(modelId);
    setIsDeployModalOpen(true);
  };

  const getCodeModel = (modelId, modelIp, modelType) => {
    navigate("/getCode", { state: { modelIp, modelId, modelType } });
  };
  const getUiModel = (model, modelIp) => {
    navigate("/getUiT", { state: { model, modelIp } });
  };
  // Function to open the confirmation modal
  const handleDeleteModel = (modelId, model) => {
    console.log("Model ID to delete:", modelId); // Debugging
    setDeleteTunedModel(model); // Store the ID of the model to delete
    setShowDeleteConfirmationModal(true); // Show the modal
  };

  // Function to delete the model and close the modal
  const confirmDeleteModel = () => {
    console.log("Confirm delete for model ID:", deleteTunedModel); // Debugging
    if (deleteTunedModel) {
      setShowDeleteConfirmationModal(false); // Close the modal
      deleteModel(deleteTunedModel); // Call delete function with the stored model ID
    }
  };

  // Function to delete the model using the API
  const deleteModel = async (model) => {
    console.log("Deleting model ID:", model); // Debugging

    setLoading(true);

    // Prepare request payload
    const requestData = {
      model_name: model?.model_name,
      type: model?.type,
    };

    // Determine API endpoint based on model type
    let apiEndpoint = "";
    if (model?.type === "tuned model") {
      apiEndpoint = "/nocode/delete-tuned-model";
    } else if (model?.type === "Deployed model") {
      apiEndpoint = "/tune/delete_deploy";
    } else {
      console.error("Invalid model type:", model?.type);
      toast("Invalid model type");
      setLoading(false);
      return; // Exit if model type is not valid
    }

    try {
      const response = await axiosInstance.delete(apiEndpoint, {
        data: requestData,
      });
      console.log("Delete response:", response); // Debugging
      toast(response?.data?.message);
      loadModels(); // Reload models after deletion
    } catch (error) {
      console.error("Error deleting model:", error); // Debugging
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteModel(model); // Retry after refreshing token
        }
      } else {
        console.error("Error deleting model:", error);
        toast(error.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the Start Deployment API
  const handleStartDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/tune/start_deploy", {
        model_name: model?.model_name,
      });
      window.location.reload();
      console.log("Start deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error starting deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the Stop Deployment API
  const handleStopDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/tune/stop_deploy", {
        model_name: model?.model_name,
      });
      window.location.reload();
      console.log("Stop deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error stopping deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call all functions once when the component mounts
    loadModels();
    // loadInstance();
    // loadSynthHistory();

    // Set interval to call them every 30 seconds
    const interval = setInterval(() => {
      loadModels();
      // loadInstance();
      //   loadSynthHistory();
    }, 30000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once when mounted

  // Load models when Tuned Model tab is active
  useEffect(() => {
    if (openTab === 2 && models.length === 0) {
      loadModels();
    }
  }, [openTab]);
  const handleAction = (modelId) => {
    setShowActionsForModel((prevModelId) =>
      prevModelId === modelId ? null : modelId
    );
  };

  // Close the action if clicked outside the div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setShowActionsForModel(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (openAction) {
      const actionElement = actionRef.current;
      if (actionElement) {
        const { bottom } = actionElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the dropdown's bottom would overflow the viewport
        if (bottom > windowHeight) {
          setIsBottomOverflowing(true); // Overflow detected
        } else {
          setIsBottomOverflowing(false); // No overflow
        }
      }
    }
  }, [openAction]);

  const handleOptionClick = (option) => {
    if (option === "viewDetail") {
      navigate("/view-detail");
    } else if (option === "deploy") {
      navigate("/deploy");
    } else if (option === "delete") {
      navigate("/delete");
    }
  };

  function capitalizeWords(str) {
    if (!str) return ""; // Check if the string is undefined, null, or empty
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const totalPages = Math.ceil(models.length / pageSize);
  const currentModel = models.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <div
        className={`${openTab === 2 ? "block" : "hidden"} overflow-x-auto`}
        id="link2"
      >
        {modelsLoading ? (
          <div className="flex justify-center items-center">
            <img src={loader} alt="Loading..." className="spinner" />
            <p className="ml-2">Loading models...</p>
          </div>
        ) : modelsError ? (
          <div className="text-red-500">Error: {modelsError}</div>
        ) : (
          <table className="w-full min-w-fit">
            <thead className="bg-table-header-bg-color">
              <tr>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Model Name
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  GPU
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Created On
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Base Model
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Type
                </th>
                <th className=" py-4 px-10 text-sm font-semibold text-center">
                  Status
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentModel.map((model, i) => (
                <tr
                  key={model._id}
                  className={`${
                    i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                  } w-full`}
                >
                  <td className="py-4 px-10 text-sm font-normal">
                    {model.model_name}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {model.feature.gpu_name}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {convertToLocalTime(model.create_date_time)}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {model.model_id}
                  </td>
                  <td className="text-sm font-medium text-center">
                    <span
                      className={`rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap ${model.type}`}
                    >
                      {capitalizeWords(model.type)}
                    </span>
                  </td>

                  <td className="text-sm font-medium text-center">
                    <span
                      className={`ml-4 rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap font-semibold ${
                        model.status === "Tuning In-progress"
                          ? "bg-yellow-100 text-yellow-500"
                          : model.status === "Container Created"
                          ? "bg-sky-100 text-sky-500"
                          : model.status === "Model Loading"
                          ? "bg-purple-100 text-purple-500"
                          : model.status === "Tuning Failed"
                          ? "bg-red-100 text-red-500"
                          : model.status === "Tuning Completed"
                          ? "bg-green-100 text-green-500"
                          : model.status === "Tuning Initialized"
                          ? "bg-pink-100 text-pink-500"
                          : model.status === "Deployment Ready"
                          ? "bg-blue-100 text-blue-500"
                          : model.status === "Deployment Initialized"
                          ? "bg-indigo-100 text-indigo-600"
                          : model.status === "Deployment Stopped"
                          ? "bg-red-100 text-red-500"
                          : model.status === "Deployment In-Progress"
                          ? "bg-orange-100 text-orange-500"
                          : "text-black py-4 px-10 text-sm font-normal"
                      }`}
                    >
                      {model.status}
                    </span>
                  </td>
                  <td>
                    <HiDotsVertical
                      className="mt-0.5 ml-12 cursor-pointer"
                      onClick={() => handleAction(model._id)}
                    />
                    {/* Dropdown buttons based on the model status */}
                    {showActionsForModel === model._id && (
                      <div
                        ref={actionRef}
                        className="absolute right-0 lg:right-auto z-10 bg-white shadow-lg rounded-md p-2 mt-2 w-48  ring-1 ring-black ring-opacity-5"
                      >
                        {/* View Details Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            [
                              "Tuning Initialized",
                              "Model Loading",
                              "Tuning In-progress",
                            ].includes(model.status)
                              ? "text-black" // Active state
                              : "text-gray-400 cursor-not-allowed pointer-events-none" // Inactive state
                          }`}
                          disabled={
                            ![
                              "Tuning Initialized",
                              "Model Loading",
                              "Tuning In-progress",
                            ].includes(model.status)
                          }
                          onClick={() => viewDetails(model)}
                        >
                          <FiEye className="mr-2 h-5 w-5" /> View Details
                        </button>
                        {/* Deploy Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Tuning Completed"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={model.status !== "Tuning Completed"}
                          onClick={() =>
                            deployModel(model._id, model.model_name)
                          }
                        >
                          <TbExternalLinkOff className="mr-2 h-5 w-5" /> Deploy
                        </button>
                        {/* Get Code Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Deployment Ready"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={model.status !== "Deployment Ready"}
                          onClick={() =>
                            getCodeModel(
                              model._id,
                              model.feature.service_ip,
                              model.type
                            )
                          }
                        >
                          <IoCodeSharp className="mr-2 h-5 w-5" /> Get Code
                        </button>

                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Deployment Ready"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={model.status !== "Deployment Ready"}
                          onClick={() =>
                            getUiModel(model, model.feature.service_ip)
                          }
                        >
                          <IoCodeSharp className="mr-2 h-5 w-5" /> Interactive
                          UI
                        </button>

                        {/* Start/Stop Button */}
                        {model.status === "Deployment Ready" ? (
                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              loading
                                ? "text-gray-400 cursor-not-allowed"
                                : "text-black"
                            }`}
                            onClick={() => !loading && handleStopDeploy(model)}
                            disabled={loading}
                          >
                            {loading ? (
                              <LoaderComponent />
                            ) : (
                              <FaRegStopCircle className="mr-2 h-5 w-5" /> // Use Stop icon for "Deployment Ready" status
                            )}
                            {loading ? "Stopping..." : "Stop"}
                          </button>
                        ) : model.status === "Deployment Stopped" ? (
                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              loading
                                ? "text-gray-400 cursor-not-allowed"
                                : "text-black"
                            }`}
                            onClick={() => !loading && handleStartDeploy(model)}
                            disabled={loading}
                          >
                            {loading ? (
                              <LoaderComponent />
                            ) : (
                              <FaRegPlayCircle className="mr-2 h-5 w-5" /> // Use Play icon for "Deployment Stopped" status
                            )}
                            {loading ? "Starting..." : "Start"}
                          </button>
                        ) : (
                          <button
                            className="flex items-center w-full text-base px-4 py-2 text-gray-400 cursor-not-allowed"
                            disabled
                          >
                            <FaRegPlayCircle className="mr-2 h-5 w-5" />
                            Start / Stop
                          </button>
                        )}

                        {/* Delete Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Tuning Failed" ||
                            model.status === "Tuning Completed" ||
                            model.status === "Deployment Stopped"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={
                            model.status !== "Tuning Failed" &&
                            model.status !== "Tuning Completed" &&
                            model.status !== "Deployment Stopped"
                          }
                          onClick={() => handleDeleteModel(model._id, model)}
                        >
                          <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
                        </button>
                      </div>
                    )}
                    {/* Confirmation Modal for Model Deletion */}
                    {showDeleteConfirmationModal &&
                      deleteTunedModel._id === model._id && (
                        <div className="fixed z-10 inset-0 overflow-y-auto">
                          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div
                              className="fixed inset-0 transition-opacity"
                              aria-hidden="true"
                            >
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span
                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true"
                            >
                              &#8203;
                            </span>
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                <div className="mt-3 text-center sm:mt-5">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Are you sure you want to delete this model?
                                  </h3>
                                </div>
                                <div className="mt-5 sm:mt-6 flex justify-center">
                                  <button
                                    type="button"
                                    className="cancel-button"
                                    onClick={() => confirmDeleteModel()} // Call confirmDeleteModel on click
                                  >
                                    Delete
                                  </button>
                                  <button
                                    type="button"
                                    className="general-button ml-5"
                                    onClick={() =>
                                      setShowDeleteConfirmationModal(false)
                                    } // Close modal
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {openAction && (
          <div
            ref={actionRef}
            className={`fixed z-10 mt-40 w-48 rounded-lg shadow-xl bg-white ring-1 ring-black ring-opacity-5 ${
              isBottomOverflowing ? "bottom-2" : "top-auto"
            } right-2`}
          >
            <div className="flex flex-col p-2">
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick("viewDetail")}
              >
                <FiEye className="mr-2" /> View Detail
              </button>
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick("deploy")}
                disabled
              >
                <TbExternalLinkOff className="mr-2 h-5 w-5 text-gray-400" />{" "}
                Deploy
              </button>
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                // onClick={() =>
                //   handleOptionClick("delete")
                // }
              >
                <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
              </button>
            </div>
          </div>
        )}
        {/* Pagination */}
        <div className=" bg-white flex justify-end items-center border-t border-gray-100 p-2 space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Rows Per Page:</span>
            <select
              className="border rounded px-2 py-1 text-sm bg-rgba(248, 249, 253, 0.5)"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm">
              {currentModel.length > 0
                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(
                    currentPage * pageSize,
                    models.length
                  )} of ${models.length}`
                : "0"}
            </span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === 1 && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="text-sm">{currentPage}</span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === totalPages && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
        {/* Deploy Modal */}
        {isDeployModalOpen && (
          <DeployModal
            isDeployModalOpen={isDeployModalOpen}
            setIsDeployModalOpen={setIsDeployModalOpen}
            // price={selectedPrice}
            modelName={selectedModel}
            modelId={selectedmodelId}
          />
        )}
      </div>
    </>
  );
};

export default TunedModelTab;
