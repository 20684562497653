import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../api/axios";

const EnterpriseEditModal = ({
  token,
  enterpriseModal,
  setEnterpriseModal,
  user,
  onSave,
  adminCredit,
}) => {
  const initialAddress = user.address?.split(",") || ["", "", "", "", ""];
  const [formData, setFormData] = useState({
    company_name: user.company_name || "",
    phone_number: user.phone_number || "",
    website_url: user.website_url || "",
    total_team_members: user.total_team_members || "",
    street_address: initialAddress[0]?.trim() || "",
    city: initialAddress[1]?.trim() || "",
    state: initialAddress[2]?.trim() || "",
    country: initialAddress[3]?.trim() || "",
    pin_code: initialAddress[4]?.trim() || "",
  });
  const BussinessapiURL = process.env.REACT_APP_BUSINESS_SERVICE_URL;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const fullAddress = `${formData.street_address}, ${formData.city}, ${formData.state}, ${formData.country}, ${formData.pin_code}`;
    const apiBody = {
      phone_number: formData.phone_number || "",
      street_address: formData.street_address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      postcode: formData.pin_code,
      // address: fullAddress,
    };

    try {
      const response = await axiosInstance.put(
        `${BussinessapiURL}/update-company`,
        JSON.stringify(apiBody),
        {
          headers: {
            "x-access-token": token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        console.log("Data updated successfully:", response.data);
        onSave({ ...formData, address: fullAddress });
        toast(response.data.message);

        setEnterpriseModal(false);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast("An unexpected error occurred. Please try again.");
    } finally {
      setEnterpriseModal(false);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[900px] bg-white outline-none focus:outline-none">
            {/* Header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <div>
                <h5 className="text-xl font-semibold">
                  Edit Enterprise Account Details
                </h5>
              </div>
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setEnterpriseModal(false)}
              >
                <MdClose className="w-5 h-5" />
              </button>
            </div>
            {/* Body */}
            <div className="relative p-6 flex-auto min-w-max">
              <div className="flex justify-between gap-10">
                <div className="w-full">
                  <label
                    htmlFor="company_name"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Company Name
                  </label>
                  <TextField
                    name="company_name"
                    id="company_name"
                    variant="outlined"
                    fullWidth
                    value={formData.company_name}
                    disabled
                    sx={{ opacity: 0.8 }}
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Phone No
                  </label>
                  <TextField
                    name="phone_number"
                    id="phone_number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits with a length between 10 and 15
                      if (/^\d{0,15}$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    value={formData.phone_number || ""}
                    error={
                      formData.phone_number &&
                      (formData.phone_number.length < 10 ||
                        formData.phone_number.length > 15)
                    }
                    helperText={
                      formData.phone_number &&
                      (formData.phone_number.length < 10 ||
                        formData.phone_number.length > 15)
                        ? "Phone number must be between 10 and 15 digits"
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="my-5 flex justify-between gap-10">
                <div className="w-full">
                  <label
                    htmlFor="website_url"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Website URL
                  </label>
                  <TextField
                    name="website_url"
                    id="website_url"
                    variant="outlined"
                    fullWidth
                    value={user.email}
                    disabled
                    sx={{ opacity: 0.8 }}
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="total_team_members"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Total Members
                  </label>
                  <TextField
                    name="total_team_members"
                    id="total_team_members"
                    variant="outlined"
                    disabled
                    sx={{ opacity: 0.8 }}
                    fullWidth
                    onChange={handleChange}
                    value={user.total_team_members}
                  />
                </div>
              </div>
              <div className="my-5 flex justify-between gap-10">
                <div className="w-full">
                  <label
                    htmlFor="street_address"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Street Address
                  </label>
                  <TextField
                    name="street_address"
                    id="street_address"
                    variant="outlined"
                    fullWidth
                    value={formData.street_address}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="city"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    City
                  </label>
                  <TextField
                    name="city"
                    id="city"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={formData.city}
                  />
                </div>
              </div>

              <div className="my-5 flex justify-between gap-10">
                <div className="w-full">
                  <label
                    htmlFor="state"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    State
                  </label>
                  <TextField
                    name="state"
                    id="state"
                    variant="outlined"
                    fullWidth
                    value={formData.state}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="country"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Country
                  </label>
                  <TextField
                    name="country"
                    id="country"
                    variant="outlined"
                    value={formData.country}
                    fullWidth
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="my-5 flex justify-between gap-10">
                <div className="w-full">
                  <label
                    htmlFor="pin_code"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    ZIP/Pin Code
                  </label>
                  <TextField
                    name="pin_code"
                    id="pin_code"
                    variant="outlined"
                    fullWidth
                    value={formData.pin_code}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="total_org_credit"
                    className="block text-sm font-bold text-secondry-clr mb-2"
                  >
                    Remaining Credits
                  </label>
                  <TextField
                    name="total_org_credit"
                    id="total_org_credit"
                    variant="outlined"
                    fullWidth
                    value={adminCredit}
                    // onChange={handleChange}
                    disabled
                    sx={{ opacity: 0.8 }}
                  />
                </div>
              </div>

              {/* <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-bold text-secondry-clr mb-2"
                >
                  Street Address
                </label>
                <TextField
                  name="address"
                  id="address"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={formData.address || ""}
                />
              </div> */}

              {/* Footer */}
              <div className="flex items-center justify-end p-6 rounded-b gap-x-3">
                <button
                  className="h-[50px] w-[100px] px-4 py-2 mr-2 text-sm font-bold text-black bg-white border border-black rounded-lg hover:bg-gray-200 hover:border-none"
                  onClick={() => setEnterpriseModal(false)}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="classic-button h-[50px] w-[100px] text-[18px] font-[600]"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default EnterpriseEditModal;
