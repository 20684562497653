import axios from "axios";
import Cookies from "js-cookie";

const apiURL = process.env.REACT_APP_BASE_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: apiURL,
  withCredentials: true, // For cookies
});

// Interceptor to add CSRF tokens to requests
axiosInstance.interceptors.request.use(
  (config) => {
    console.log( "amittttttttttttttttttttttttttttttttttttt",Cookies.get("csrf_access_token"))
    const accessCsrfToken = Cookies.get("csrf_access_token");
    console.log(accessCsrfToken);
    const refreshCsrfToken = Cookies.get("csrf_refresh_token");
    console.log(refreshCsrfToken);

    if (config.url.includes("/user/refresh_token")) {
      if (refreshCsrfToken) {
        config.headers["X-CSRF-TOKEN"] = refreshCsrfToken;
      }
    } else {
      if (accessCsrfToken) {
        config.headers["X-CSRF-TOKEN"] = accessCsrfToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
