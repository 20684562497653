import React, { useEffect, useState } from "react";
import CostAnalysis from "./CostAnalysis";
import BillingPayment from "./BillingPayment";
import Overview from "./Overview";
// import Projects from './Projects';

function Tab() {
  const [activeTab, setActiveTab] = useState(
    parseInt(localStorage.getItem("activeTab")) || 1
  );
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    // Save the active tab to localStorage
    localStorage.setItem("activeTab", tabNumber);
    window.location.hash = `#tab${tabNumber}`;
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(4);
      const tabNumber = parseInt(hash) || 1;
      setActiveTab(tabNumber);
      // Save the active tab to localStorage
      localStorage.setItem("activeTab", tabNumber);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="px-8 pt-2 w-full bg-white shadow-md flex gap-x-6 items-end justify-center overflow-x-auto">
            <span
              className={`min-w-fit text-[18px] font-[500] cursor-pointer pb-3 px-1 ${
                activeTab === 1
                  ? "text-gen-color font-[700] border-b-[3px] border-b-gen-color"
                  : "border-b-[3px] border-white"
              }`}
              onClick={() => handleTabClick(1)}
            >
              Information
            </span>
            <span
              className={`min-w-fit text-[18px] font-[500] cursor-pointer pb-3 px-1 ${
                activeTab === 3
                  ? "text-gen-color font-[700] border-b-[3px] border-b-gen-color"
                  : "border-b-[3px] border-white"
              }`}
              onClick={() => handleTabClick(3)}
            >
              Billing
            </span>
            <span
              className={`min-w-fit text-[18px] font-[500] cursor-pointer pb-3 px-1 ${
                activeTab === 4
                  ? "text-gen-color font-[700] border-b-[3px] border-b-gen-color"
                  : "border-b-[3px] border-white"
              }`}
              onClick={() => handleTabClick(4)}
            >
               Team Logs
            </span>
          </div>

          {/* <li className="flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg py-7 block border-x border-gray-50" +
                                    (activeTab === 2 ? 'active text-tab-active-color rounded-t-2xl bg-white font-bold' : 'text-gray-60 bg-tab-not-active-color font-medium')
                                }
                                onClick={() => handleTabClick(2)}
                            >
                                Projects
                            </div>
                        </li> */}
{/* 
          <li className="flex-auto text-center cursor-pointer">
                            <div
                                className={
                                    "text-lg font-semibold py-7 rounded-t-xl " +
                                    (activeTab === 4 ? 'active text-tab-active-color bg-white z-50 font-bold' : 'text-gray-60 bg-[#E8E9EE] translate-y-[10%] rounded-tl-none   z-10 ')
                                }
                                onClick={() => handleTabClick(4)}
                            >
                                User Logs
                            </div>
                        </li> */}
          <hr />

          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto mb-14">
              <div className="tab-content tab-space">
                <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`}>
                  <Overview />
                </div>
                {/* <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
                                    <Projects />
                                </div> */}
                <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`}>
                  <BillingPayment />
                </div>
                <div className={`tab-pane ${activeTab === 4 ? "active" : ""}`}>
                  <CostAnalysis />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tab;
