import axios from 'axios';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../api/axios';


function CostAnalysis() {
    const [getCreditHistoryRes, setGetCreditHistoryRes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
  
    const BussinessapiURL = process.env.REACT_APP_BUSINESS_SERVICE_URL;
  
    useEffect(() => {
      const getCreditHistory = () => {
        const token = localStorage.getItem("token");
        const headers = {
          'x-access-token': token,
          'Content-Type': 'application/json',
        };
  
        axiosInstance
          .get(`${BussinessapiURL}/credit-history`, { headers })
          .then(response => {
            console.log(response); // Log to confirm the structure
            const creditHistory = response?.data?.data?.credit_history || [];
            setGetCreditHistoryRes(creditHistory);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };
  
      getCreditHistory();
    }, [BussinessapiURL]);
  
    const totalPages = Math.ceil(getCreditHistoryRes.length / pageSize);
    const currentCost = getCreditHistoryRes.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  
    const handlePageChange = (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
  
    const handlePageSizeChange = (e) => {
      setPageSize(Number(e.target.value));
      setCurrentPage(1);
    };
  
    return (
      <>
        <div className='flex justify-between py-3'>
          <div className='text-base font-semibold text-secondry-clr pt-3'>
            List ({getCreditHistoryRes.length})
          </div>
        </div>
  
        <table className="min-w-full">
          <thead className='bg-table-bg-head-clr'>
            <tr>
              <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Email</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Plan Name</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Date & Time</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-secondry-clr">Cost</th>
            </tr>
          </thead>
          <tbody>
            {currentCost.map((ele, i) => (
              <tr key={i} className={i % 2 !== 0 && "bg-page-bg-color bg-opacity-50"}>
                <td className="py-2 px-4 text-sm font-normal text-secondry-clr">{ele?.email}</td>
                <td className="py-2 px-4 text-sm font-normal text-secondry-clr">{ele?.plan_name}</td>
                <td className="py-2 px-4 text-sm font-normal text-secondry-clr">{ele?.created_date}</td>
                <td className="py-2 px-4 text-sm font-normal text-secondry-clr">${ele?.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
  
        <div className="bg-white flex justify-end items-center border-t border-gray-100 p-2 space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Rows Per Page:</span>
            <select
              className="border rounded px-2 py-1 text-sm bg-rgba(248, 249, 253, 0.5)"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm">
              {currentCost.length > 0
                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(
                    currentPage * pageSize,
                    getCreditHistoryRes.length
                  )} of ${getCreditHistoryRes.length}`
                : "0"}
            </span>
            <button
              className={`px-3 py-1 border rounded ${currentPage === 1 && "opacity-50 cursor-not-allowed"}`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="text-sm">{currentPage}</span>
            <button
              className={`px-3 py-1 border rounded ${currentPage === totalPages && "opacity-50 cursor-not-allowed"}`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      </>
    );
  }
  
  export default CostAnalysis;
  